<template>
<div class="col-12">
    <div class="p-3 mb-3">
        <div class="flex align-items-center justify-content-between">
            <DataTable :value="domains" :rows="10" responsiveLayout="scroll"
                       class="w-full" stripedRows :loading="loading">
                <Column field="domain" header="Domain"></Column>
                <Column field="total_count" header="Total Count"></Column>
                <Column field="created_at" header="Created At"></Column>
                <Column field="last_seen" header="Updated At"></Column>
                <Column field="type" header="Action">
                    <template #body="{ data }">
                        <!--Disable data entry if ignore is selected-->
                        <SelectButton v-model="data.action" :options="actions" aria-labelledby="basic"/>
                    </template>
                </Column>
                <Column field="type" header="Submit">
                    <template #body="{ data }">
                        <Button type="button" :disabled="data.action==='Ignore'?true:null"
                                :class="data.action==='Ignore'?'bg-gray-400':'bg-green-400'"
                                :icon="data.action==='Ignore'?'pi pi-times':'pi pi-check'"
                                @click="submitDomain(data['domain'], data)">
                        </Button>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "CyberlockerReview",

    data: function() {
        return {
            domains: [],
            loading: false,
            error: null,

            actions: ['Blacklist', 'Ignore', 'Whitelist']
        }
    },

    mounted() {
        this.getCyberlockerDomains();
    },

    methods: {
        getCyberlockerDomains() {
            this.loading = true;
            this.$factory.datareview.getCyberlockerDomains()
                .then((response) => {
                    // Add a new field to the response to store the action
                    response.forEach((item) => {
                        item.action = 'Ignore';
                    });
                    this.domains = response;
                    console.debug(this.domains);
                })
                .catch((error) => {
                    this.error = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        submitDomain(domain, entry) {
            this.loading = true;
            console.debug("Submitting domain: " + domain + " with action: " + entry['action'])
            let submitBody = {
                'domain': domain,
                'action': entry['action']
            }
            this.$factory.datareview.putCyberlockerDomains(submitBody)
                .then(() => {
                    this.getCyberlockerDomains();
                })
                .catch((error) => {
                    this.error = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },

}
</script>

<style scoped>

</style>

<template>
    <div class="flex flex-column flex-auto">
        <div class="p-5">
            <TabView v-model:activeIndex="pageIndex">
                <TabPanel>
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <span class="flex font-bold white-space-nowrap text-left text-xl">Suspicious Domain Reviewing</span>
                        </div>
                    </template>
                    <DomainReview/>
                </TabPanel>
                <TabPanel v-if="this.$store.getters.getUserRoles.includes('admin')">
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <span class="flex font-bold white-space-nowrap text-left text-xl">Cyberlocker</span>
                        </div>
                    </template>
                    <CyberlockerReview/>
                </TabPanel>

            </TabView>
        </div>
    </div>


</template>

<script>

import DomainReview from "@/components/modals/DomainReview";
import CyberlockerReview from "@/components/modals/CyberlockerReview";

export default {
    name: "Domains",
    components: {
        DomainReview,
        CyberlockerReview
    },

    data: function() {
        return {
            pageIndex: 0,
        }
    },

    mounted() {

    },

    methods: {

    },
}
</script>

<style scoped>

</style>

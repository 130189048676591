<template>

<div class="grid">
    <!-- Presets -->
    <div class="col-6" v-if="verified">
        <Button label="All Hard Exclude" class="w-full" @click="selectAllHardExclude"/>
    </div>
    <div class="col-6" v-if="verified">
        <Button label="All Suspicious" class="w-full" @click="selectAllSuspicious"/>
    </div>

    <div class="col-3" v-if="verified">
        <Button label="All Spam" class="w-full" @click="selectAllSpam"/>
    </div>
    <div class="col-3" v-if="verified">
        <Button label="All Direct Download" class="w-full" @click="selectAllDirectDownload"/>
    </div>
    <div class="col-3" v-if="verified">
        <Button label="All Torrent" class="w-full" @click="selectAllTorrent"/>
    </div>
    <div class="col-3" v-if="verified">
        <Button label="All Streaming" class="w-full" @click="selectAllStreaming"/>
    </div>


    <!-- Table -->
    <div class="col-12">
        <div class="p-3 mb-3">
            <div class="flex align-items-center justify-content-between">
                <!--Primary table-->
                <DataTable :value="domainData" :rows="10" responsiveLayout="scroll"
                           v-model:filters="tableFilters"  filter-display="menu"
                           @filter="onTableFilter($event)" lazy
                           class="w-full" stripedRows :loading="suspiciousDomainsLoading">
                    <template #loading> Loading new domain data. Please wait. </template>
                    <Column field="domain" header="Domain">
                        <template #body="{ data, field }">
                            <a @click="showPopupTable(data[field])" v-ripple style="text-decoration: none; color: inherit;"
                               class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                                <span class="line-height-3 font-medium">{{ data[field] }}</span>
                            </a>
                        </template>
                    </Column>
                    <Column field="username" header="Submitter" :showFilterMatchModes="false">
                        <template #body="{ data }">
                            {{ data.username }}
                        </template>
                        <template #filter="{ filterModel }">
                            <Dropdown v-model="filterModel.value" :options="tableUsernameOptions" placeholder="Select One" class="p-column-filter" showClear></Dropdown>
                        </template>
                    </Column>
                    <Column field="date" header="Submitted Date"> </Column>
                    <Column field="filter" header="Filter">
                        <template #body="{ data, field }">
                            <SelectButton v-model="data[field]" :options="filterOptions" aria-labelledby="basic" />
                        </template>
                    </Column>
                    <Column field="type" header="Type">
                        <template #body="{ data, field }">
                            <!--Disable data entry if ignore is selected-->
                            <SelectButton v-model="data[field]" :options="domainTypes" aria-labelledby="basic"
                                          :disabled="data['ignore']==='Ignore'?true:null"/>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="flex flex-row justify-content-between">
                <!--Bar at the top of the table-->
                <Toolbar v-if="domainData!==null" class="flex min-w-full mb-4">
                    <template #end>
                        <Button label="Export" icon="pi pi-upload" severity="help" @click="submitNewSusDomains" />
                    </template>
                </Toolbar>
            </div>
        </div>
    </div>
</div>

<!--Popup table-->
<Dialog v-model:visible="popupTableToggled" :header="popupTableDomain" maximizable modal
        :style="{ width: '75vw' }" >
    <TabView class="w-full h-screen">
        <TabPanel header="Preview">
            <!-- zoom out the iframe by 50% -->
            <iframe :src="'https://' + popupTableDomain" class="w-full h-screen"/>
        </TabPanel>

        <TabPanel header="Previously Seen">
            <DataTable :value="popupTableData" :rows="10" :totalRecords="popupTableTotalRecords" ref="popupDT"
                       lazy paginator scrollable scrollHeight="flex"
                       :loading="popupTableLoading" @page="onPopupTablePage($event)">
                <Column field="Link" header="Links">
                    <template #body="{ data, field }">
                        <a :href="'https://' + data[field]" target="_blank" v-ripple style="text-decoration: none; color: inherit;"
                           class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                            <span class="line-height-3 font-medium">{{ data[field] }}</span>
                        </a>
                    </template>
                </Column>
            </DataTable>
        </TabPanel>
    </TabView>
</Dialog>
</template>

<script>
import globals from "@/globals";
import {FilterMatchMode} from "primevue/api";

export default {
    name: "DomainReview",

    data: function() {
        return {
            popupTableToggled: false,
            popupTableDomain: null,
            popupTableHTML: null,
            popupTableData: null,
            popupTableLoading: true,
            popupTableTotalRecords: 0,
            popupTableLazyParams: {},
            domainData: null,
            suspiciousDomainsLoading: true,

            domainTypes: ['unknown', 'spam', 'direct_download', 'torrent', 'streaming'],
            filterOptions: ['Hard Exclude', 'Ignore', 'Suspicious Domain'],

            tableFilters: {
                username: { value: null, matchMode: FilterMatchMode.EQUALS }
            },

            tableLazyParams: {
                first: 0,
                rows: 10,
                sortField: "date",
                sortOrder: -1,
                filters: {
                    username: { value: null, matchMode: FilterMatchMode.EQUALS }
                }
            },

            tableUsernameOptions: [],

            companyName: null,
            userRoles: [],
        }
    },

    computed: {
        verified() {
            return this.companyName === 'Cyber Theft Watch' && this.userRoles.includes('admin')
        }
    },

    mounted() {
        this.popupTableLazyParams = {
            first: 0,
            rows: 10,
        };
        this.getSusDomainSubmitters();
        this.getSusDomainData();
    },

    methods: {
        getSusDomainData() {
            this.suspiciousDomainsLoading = true;
            this.$factory.datareview.getSusDomainData(this.tableLazyParams.filters.username.value).then((data) => {
                this.domainData = data;
                console.debug(data)
            }).catch((error) => {
                this.$emitter.emit("bad-toast", "Can't get suspicious domains");
                console.error("Can't get sus domain data", error);
            }).finally(() => {
                this.suspiciousDomainsLoading = false;
            });
        },

        submitNewSusDomains() {
            console.debug(this.domainData);
            this.suspiciousDomainsLoading = true;
            this.$factory.datareview.submitNewSusDomains(this.domainData).then(() => {
                this.$emitter.emit("good-toast", "Suspicious domains sent to server");
            }).catch((error) => {
                this.$emitter.emit('bad-toast', 'Error submitting suspicious domains');
                this.domainData = null;
                console.error("Can't submit sus domain data", error);
            }).finally(() => {
                this.getSusDomainData()
                this.suspiciousDomainsLoading = false;
            });
        },

        onTableFilter() {
            // Prevents infinite loop
            this.tableLazyParams.filters = JSON.parse(JSON.stringify(this.tableFilters));
            this.getSusDomainData();
        },

        getSusDomainPopupData() {
            const requestOptions = {
                method: "GET",
                credentials: 'include',
                headers: new Headers({
                    "Content-Type": "application/json",
                    "XSRF-TOKEN": this.$store.getters.getCSRFToken,
                })
            };

            let uri = globals.host + "/review/getassetlinksbydomain" +
                "?domain=" + this.popupTableDomain +
                "&start=" + this.popupTableLazyParams.first +
                "&limit=" + this.popupTableLazyParams.rows;

            // Get from DB
            fetch(uri, requestOptions)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    console.log("data", data.data);

                    this.popupTableData = data.data;
                    this.popupTableTotalRecords = data.data.length;
                    this.popupTableTotalRecords = data.totalRecords;
                    this.popupTableLoading = false;

                })
                .catch(error => {
                    console.error("Can't fetch popup table data", error);
                    if ([401, 429].includes(error)) {
                        this.$emitter.emit("logout");
                    }
                    return []
                });
        },

        getSusDomainSubmitters() {
            this.$factory.datareview.getSusDomainSubmitters().then((data) => {
                data.forEach((item) => {
                    this.tableUsernameOptions.push(item.User);
                });
            }).catch((error) => {
                this.$emitter.emit("bad-toast", "Can't get suspicious domain submitters");
                console.error("Can't get sus domain submitters", error);
            });
        },

        showPopupTable(domain) {
            this.popupTableDomain = domain;
            this.popupTableToggled = true;
            this.getPopupTableData();
        },

        getPopupTableData() {
            this.popupTableLoading = true;
            this.getSusDomainPopupData();
            // this.popupTableLoading = false;
        },

        onPopupTablePage(event) {
            this.popupTableLazyParams = event;
            console.log("onPopupTablePage", event);
            this.getPopupTableData();
        },

        selectAllHardExclude() {
            this.domainData.forEach((item) => {
                item.filter = "Hard Exclude";
            });
        },

        selectAllSuspicious() {
            this.domainData.forEach((item) => {
                item.filter = "Suspicious Domain";
            });
        },

        selectAllSpam() {
            this.domainData.forEach((item) => {
                item.type = "spam";
            });
        },

        selectAllDirectDownload() {
            this.domainData.forEach((item) => {
                item.type = "direct_download";
            });
        },

        selectAllTorrent() {
            this.domainData.forEach((item) => {
                item.type = "torrent";
            });
        },

        selectAllStreaming() {
            this.domainData.forEach((item) => {
                item.type = "streaming";
            });
        },
    },

    watch: {
        '$store.state.userinfo': {
            handler() {
                let userInfo = this.$store.state.userinfo;
                this.username = userInfo.username;
                this.companyId = userInfo.companyid;
                this.companyName = userInfo.companyname;
                this.userRoles = userInfo.roles;
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>

<style scoped>

</style>
